import { Component, OnInit, Input } from '@angular/core';
import { fadeInOut } from '../../services/animations';
/*import { ContactService } from '../../services/contact.service';*/
import { Contact } from '../../models/contact.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { Email } from '../../models/email.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Utilities } from '../../services/utilities';
import { ActiveObligo } from '../../models/active-obligo.model';
import { ObligoService } from '../../services/obligo.service';
import { ContactService } from '../../services/contact.service';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  animations: [fadeInOut]
})
export class ContactComponent implements OnInit {

  private customerSupportEmail: string;
  private customerSupportName: string;
  private debtorManagementEmail: string;
  private debtorManagementName: string;
  private readonly _baseUrl: string;
  private user: User = new User;
  private nfkdkdnr: string;
  private ndktdebitnr: string;
  private messageText: string;

  factorContactDataRows = [];

  contact: FormGroup;

  // Radio button 
  selectedOption: string;
  selection: any = [
    'customerSupport',
    'debtorManagement'
  ];
 
  constructor(private accountService: AccountService, private alertService: AlertService,
    private contactService: ContactService, private httpClient: HttpClient, private authService: AuthService,
    private router: Router, private obligoService: ObligoService, private meta: Meta) {
    this._baseUrl = 'api/down/download';
    this.selectedOption = '';
    this.nfkdkdnr = sessionStorage.getItem('nfkdkdnr');
    this.ndktdebitnr = sessionStorage.getItem('ndktdebitnr');
    this.messageText = "Guten Tag,\n\nbitte ändern Sie die Stammdaten des Kontos wie folgt:\n(Änderungswünsche hier eingeben)";
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  // load user data
  @Input()
  isGeneralEditor = false;

  ngOnInit() {

    //
    if (this.nfkdkdnr == null) {
      this.contact = new FormGroup({
        contactPerson: new FormControl('', Validators.required),
        contactPhone: new FormControl('', Validators.required),
        contactFax: new FormControl(''),
        messageText: new FormControl('', Validators.required),
        fromAddress: new FormControl('', Validators.required),
        subject: new FormControl('', Validators.required),
      });
      this.selectedOption = 'customerSupport';
    } else {
      this.contact = new FormGroup({
        contactPerson: new FormControl('', Validators.required),
        contactPhone: new FormControl('', Validators.required),
        contactFax: new FormControl(''),
        messageText: new FormControl(this.messageText, Validators.required),
        fromAddress: new FormControl('', Validators.required),
        subject: new FormControl(this.nfkdkdnr + this.ndktdebitnr, Validators.required),
      });
      this.selectedOption = 'debtorManagement';
    }
    
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
      // load user data
      if (!this.isGeneralEditor) {
        this.loadCurrentUserData();
       
      }
    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
  }

  radioChangeHandler(event) {
    this.selectedOption = event.target.value;
  }

  onSubmit({ value }: { value: Contact }) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

    if (this.selectedOption !== '') {

      if (value.contactPerson === '' || value.contactPerson === null) {

        this.alertService.showMessage("Bitte geben Sie den Vor- und Nachnamen des Interessenten an!", "", MessageSeverity.error);

      } else if (value.contactPhone === '' || value.contactPhone === null) {

        this.alertService.showMessage("Bitte geben Sie eine Telefonnummer an!", "", MessageSeverity.error);

      } else if (value.fromAddress === '' || value.fromAddress === null) {

        this.alertService.showMessage("Bitte geben Sie eine E-Mail an!", "", MessageSeverity.error);

      } else if (! /.+@.+\..+/.test(value.fromAddress)) {

        this.alertService.showMessage("Die E-Mail ist nicht valide!", "", MessageSeverity.error);

      } else if (value.subject === '' || value.subject === null) {

        this.alertService.showMessage("Bitte geben Sie einen Betreff an!", "", MessageSeverity.error);

      } else if (value.messageText === '' || value.messageText === null) {

        this.alertService.showMessage("Bitte formulieren Sie eine Nachricht!", "", MessageSeverity.error);

      } else {
        
        switch (this.selectedOption) {
          case "customerSupport": {
  
            this.sendEmailToCustomerSupport({ value });

            break;
          }
          case "debtorManagement": {

            this.sendEmailToDebtorManagement({ value });

            break;
          }
          default: {
           /* console.log("Invalid choice");*/
            break;
          }
        }
      }
      } else {
        this.alertService.showMessage("Bitte w&auml;hlen Sie einen Betreuer aus.", "", MessageSeverity.warn);
        }

        } else {
          this.authService.logout();
          this.router.navigateByUrl('/login');
        }
    } catch (error) {
      console.log(error);
    }
    sessionStorage.removeItem('nfkdkdnr');
    sessionStorage.removeItem('ndktdebitnr');
  }

  sendEmailToCustomerSupport({ value }: { value: Contact }) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
    //console.log("--------------");
    //console.log('Ansprechpartner:' + ' ' + value.contactPerson + '\n' +
    //  'Telefon: ' + ' ' + value.contactPhone + '\n' +
    //  'Fax: ' + ' ' + value.contactFax + '\n' +
    //  'Mitteilung: ' + ' ' + value.messageText + '\n' +
    //  'von E-Mail: ' + ' ' + value.fromAddress + '\n' +
    //  'an E-Mail: ' + ' ' + this.customerSupportEmail + '\n' +
    //  'Betreff: ' + ' ' + value.subject
    //);
    //console.log("Kundenbetreuung " + this.customerSupportName + " ausgewählt.");
        
    const email: Email = {
      contactPerson: value.contactPerson,
      contactPhone: value.contactPhone,
      contactFax: value.contactFax,
      messageText: value.messageText,
      fromAddress: value.fromAddress,
      toAddress: this.customerSupportEmail,
      subject: "Nachricht aus Crefo-Online von Obligo " + this.user.nfkdkdnr + ": " + value.subject
    };

    this.contactService.createFactorContactEmail(email)
      .subscribe(
        success => console.log(success),
        error => console.log(error)
    ), error => this.onCurrentWebServiceDataLoadFailed(error);


      this.alertService.showMessage("Die E-Mail wurde an " + this.customerSupportName + " übermittelt.", "", MessageSeverity.success);

    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }

    } catch (error) {
      console.log(error);
    }
  }

  sendEmailToDebtorManagement({ value }: { value: Contact }) {
  try {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

      //console.log("--------------");
      //console.log('Ansprechpartner:' + ' ' + value.contactPerson + '\n' +
      //  'Telefon: ' + ' ' + value.contactPhone + '\n' +
      //  'Fax: ' + ' ' + value.contactFax + '\n' +
      //  'Mitteilung: ' + ' ' + value.messageText + '\n' +
      //  'von E-Mail: ' + ' ' + value.fromAddress + '\n' +
      //  'an E-Mail: ' + ' ' + this.debtorManagementEmail + '\n' +
      //  'Betreff: ' + ' ' + value.subject
      //);
      //console.log("Debitorenmanagement " + this.debtorManagementName + " ausgewählt.");


      const email: Email = {
        contactPerson: value.contactPerson,
        contactPhone: value.contactPhone,
        contactFax: value.contactFax,
        messageText: value.messageText,
        fromAddress: value.fromAddress,
        toAddress: this.debtorManagementEmail,
        subject: value.subject
      };

      this.contactService.createFactorContactEmail(email)
        .subscribe(
          success => success,
          error => error
        ), error => this.onCurrentWebServiceDataLoadFailed(error);

      this.alertService.showMessage("Die E-Mail wurde an " + this.debtorManagementName + " übermittelt.", "", MessageSeverity.success);

    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }

    } catch (error) {
      console.log(error);
    }
  }

  // load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));   
    }
    else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
    
  }

 
factorUser=[];

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    try {

      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        this.alertService.stopLoadingMessage();
        this.user = user;

        //user
        this.contactService.getUserFactor()
          .subscribe(data => {
            this.factorUser = data;

            for (var i = 0; i < this.factorUser.length; i++) {
              if (this.user.nmndid == null || this.user.nmndid === 0) {
                this.user.nmndid = this.factorUser[i].nmndid;
              }
            }
      
            if (this.user.nfkdkdnr === 0 || this.user.nfkdkdnr === null) {

              this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
              if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
                this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
              }
            }           

                  // Webservice calls
                  this.contactService.getFactorContactData(this.user.nmndid, this.user.nfkdkdnr)
                    .subscribe(data => {
                      setTimeout(() => {
                        this.factorContactDataRows = data;                       

                        for (let i = 0; i < this.factorContactDataRows.length; i++) {
                          this.customerSupportEmail = this.factorContactDataRows[i].emaiL10;
                          this.customerSupportName = this.factorContactDataRows[i].namE10;
                          this.debtorManagementEmail = this.factorContactDataRows[i].emaiL11;
                          this.debtorManagementName = this.factorContactDataRows[i].namE11;
                        }
                      }, 500);
                    });
          });

      }

    } catch (error) {
      console.log(error);
    }
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  private onCurrentWebServiceDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Load Error", `Unable to retrieve web service data from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`, MessageSeverity.error, error);
    console.log("Load Error: Unable to retrieve web service data from the server.\r\nErrors: " + error);
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  downloadPdf() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {


          this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
          if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
            this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
          }

        const mediaType = 'application/pdf';
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );


        return this.httpClient.get(this._baseUrl + '?' + 'file=' + "KurzfragebogenFactoring.pdf" + '&' + 'nmndid=' + this.user.nmndid + '&' + 'nfkdkdnr=' + this.user.nfkdkdnr + '&' + 'expectedFolder=' + "none", { headers: httpHeaders, responseType: 'blob' }).subscribe(
      (response) => {

        const blob = new Blob([response], { type: mediaType });
        saveAs(blob, 'KurzfragebogenFactoring.pdf');
      }    
      ,
      error => {
        this.alertService.showMessage("Kein Blanko-Kurzfragebogen für Factoring vorhanden. Bitte informieren Sie Ihren Factoring-Partner.", "", MessageSeverity.info);
        console.log(error);
      });

    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
    } catch (error) {
      console.log(error);
    }
  }
}
