import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-upload-results-popup',
  templateUrl: './upload-results-popup.component.html',
  styleUrls: ['./upload-results-popup.component.css']
})
export class UploadResultsPopupComponent {
  @Input() successfulUploads: string[] = [];
  @Input() failedUploads: { filename: string; reason: string }[] = [];
  isVisible: boolean = false;

  // Zeigt das Popup an
  showPopup() {
    this.isVisible = true;
  }

  // Schließt das Popup
  closePopup() {
    this.isVisible = false;
  }
}
