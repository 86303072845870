import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { RiskService } from 'src/app/services/risk.service';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { ObligoService} from '../../services/obligo.service';
import { User } from '../../models/user.model';
import { Obligo } from '../../models/obligo.model';
import { UserObligo } from '../../models/user-obligo.model';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
//Pagination
import * as _ from 'underscore';
import { NrsigaService } from '../../services/nrsiga.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { parse } from '../../../../node_modules/ts-node/dist/index';
import { Meta } from '@angular/platform-browser';
registerLocaleData(localeDe, 'de');

@Component({
  selector: 'risk',
  templateUrl: './risk.component.html',
  styleUrls: ['./risk.component.css'],
  animations: [fadeInOut]
})
export class RiskComponent implements AfterViewInit, OnInit {

  //Load data before view
  ngAfterViewInit(): void {
    this.onClickLoadData(1);
  }

  printerIcon = require("../../assets/images/other/printer.png");

  debtorNdktid: number;
  debtorNdktkdnr: number;
  debtorNmndid: number;
  debtorCdktname1: string;
  debtordktOrt: string;
  debtorNdktdebitnr: number;
  debtorNrsiga: number;
  ndebhnr: number;
  ndebunr: number;
  ndebitorid: number;
  konzernObligos: Obligo[] = [];
  userObligos: UserObligo[] = [];
  konzernObligoNumbers = [];
  saldoSumme = 0;
  nrsigekauftSumme = 0;
  nrsiumsljSumme = 0;
  limitktoSumme = 0;

  private user: User = new User();

  debtorRiskInformationDataRows = [];
  debtorRiskMainDataRows = [];
  //debtorRiskDebtorsDataRows = [];
  debtorRiskBankDetailsDataRows = [];
  debtorNRSIGARow = [];
  debtorRiskConditionDataRows = [];
  debitorRiskInfoAllData = [];

  dktkzlasT1;
  dktkzlasT2;
  dktwunerl: boolean = false;

  ddktaufdatPruefen;
  dktlimitbisPruefen;
  drsibewegdatPruefen;
  ddktlimitabPruefen;

  limitvomPruefe;
  limitbisPruefe;

  datumDddktdatumwunPruefen;

  leereDatumAnzeigen: string = " ";
  drsimahn: any;

  //Begriffserklärung
  riskLim = [];
  selectedRiskLim:any;
  limitBez;

  //Checkbox überprüfen
  checked = false;
  disabled = false;
  checkedlastschrift1 = false;
  disabledlastschrift1 = false;
  checkedlastschrift2 = false;
  disabledlastschrift2 = false;

  //Attribute for page rendering event if data is available 
  isDataAvailable = false;

  /**
   * 
   * @param accountService
   * @param alertService
   * @param riskService
   */

  constructor(private accountService: AccountService, private alertService: AlertService, private riskService: RiskService,
    private spinner: NgxSpinnerService, private nrsigaService: NrsigaService, private authService: AuthService, private router: Router, private meta: Meta, private obligoService: ObligoService) {
    this.debtorNrsiga = 2;
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }
  
  @Input()
  isGeneralEditor = false;

  ngOnInit() {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
      // load user data
      if (!this.isGeneralEditor) {
        this.loadCurrentUserData();
      }

      for (var i = 0; i < this.accountService.currentUser.roles.length; i++) {
        if (this.accountService.currentUser.roles[i] === 'user3' || this.accountService.currentUser.roles[i] === 'user4') {
          this.userrisk = false;
        }
      }

      this.debtorRiskMainDataRows = JSON.parse(localStorage.getItem('selectedOptions'));

      if (this.userrisk) {
        //Page rendering event if data is available 
        if (this.debtorRiskMainDataRows.length > 0) {
          this.isDataAvailable = true;
        } else if (this.debtorRiskMainDataRows.length === 0) {
          this.alertService.showMessage("Bitte w&auml;hlen Sie einen Debitor im Fenster Debitorenkonten aus!", "", MessageSeverity.warn);

          //Load spinner
          this.spinner.show();
        }
      } else {
        this.alertService.showMessage("Die Sicht auf dieses Modul ist nicht freigeschaltet.", "", MessageSeverity.info);
      }
    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
  }

  onClickLoadData(paginationPoistion) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        for (var i = 0; i < this.accountService.currentUser.roles.length; i++) {
          if (this.accountService.currentUser.roles[i] === 'user3' || this.accountService.currentUser.roles[i] === 'user4') {
            this.userrisk = false;
          }
        }

        if (this.userrisk) {
          for (let i = 0; i < this.debtorRiskMainDataRows.length; i++) {
            this.debtorNdktid = this.debtorRiskMainDataRows[paginationPoistion - 1].ndktid;
            this.debtorNdktkdnr = this.debtorRiskMainDataRows[paginationPoistion - 1].ndktkdnr;
            this.debtorNmndid = this.debtorRiskMainDataRows[paginationPoistion - 1].nmndid;
            this.debtorCdktname1 = this.debtorRiskMainDataRows[paginationPoistion - 1].cdktnamE1;
            this.debtorNdktdebitnr = this.debtorRiskMainDataRows[paginationPoistion - 1].ndktdebitnr;
            this.debtordktOrt =  this.debtorRiskMainDataRows[paginationPoistion - 1].cdktort;
            this.ndebhnr =  this.debtorRiskMainDataRows[paginationPoistion - 1].ndebhnr;
            this.ndebunr = this.debtorRiskMainDataRows[paginationPoistion - 1].ndebunr;
            this.ndebitorid = this.debtorRiskMainDataRows[paginationPoistion - 1].ndebid;
          }
          /*
          this.konzernObligoNumbers = JSON.parse(localStorage.getItem("konzernObligoNumbers"));
          this.debtorRiskDebtorsDataRows = [];
          this.konzernObligoNumbers.forEach(nfkdkdnr => {
            // Webservice call
            this.riskService.getDebtorRiskDebtors(this.debtorNmndid, this.debtorNdktid, nfkdkdnr).subscribe(data => {
              this.debtorRiskDebtorsDataRows.push(data);
            });
          });*/
          

          this.riskService.getDebtorRiskLim().subscribe(data => {
            this.riskLim = data;
          });

          //this.getNrsiga();    
        } else {
          this.alertService.showMessage("Die Sicht auf dieses Modul ist nicht freigeschaltet.", "", MessageSeverity.info);
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  //Spinner
  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }

  risikoLimitab;

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    var nfkdkdnr = this.user.nfkdkdnr; 
    var nfkdkonzern = this.user.nfkdkonzern; 


    for (var i = 0; i < this.accountService.currentUser.roles.length; i++) {
      if (this.accountService.currentUser.roles[i] === 'user3' || this.accountService.currentUser.roles[i] === 'user4') {
        this.userrisk = false;
      }
    }
    
    if (nfkdkdnr === 0 && nfkdkonzern === 0) {
      nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
    }
    
    if (nfkdkonzern === 0) {
      this.getNrsiga("[" + nfkdkdnr.toString() + "]"); //Die eckigen Klammern sind notwendig, um einen JSON-String zu imitieren.
    } else {
      this.obligoService.getKonzernObligos(this.user.nmndid, nfkdkdnr, nfkdkonzern).subscribe(data => {
        this.konzernObligos = data;

        this.konzernObligos.forEach(konzernObligo => {
          this.konzernObligoNumbers.push(konzernObligo['nfkdkdnr']);
        });

        this.getNrsiga(JSON.stringify(this.konzernObligoNumbers));
      }); 
    }       
  }

  getNrsiga(oblNumStr: string) {    
    try {     
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        for (var i = 0; i < this.accountService.currentUser.roles.length; i++) {
          if (this.accountService.currentUser.roles[i] === 'user3' || this.accountService.currentUser.roles[i] === 'user4') {
            this.userrisk = false;
          }
        }

        if (this.userrisk) {      
          this.nrsigaService.getNrsigaData(this.debtorNmndid, this.debtorNdktid, this.debtorNdktkdnr).subscribe(data => {
            for (let i = 0; i < data.length; i++) {
              this.debtorNrsiga = data[i].nrsiga;
            }
          });

          // Webservice calls
          if (this.debtorNrsiga > 0) {            
            this.riskService.getDebtorRiskDebInfo(this.debtorNrsiga, this.debtorNmndid, oblNumStr, this.ndebitorid).subscribe(data => {
              this.debitorRiskInfoAllData = data;

              this.debitorRiskInfoAllData.forEach(debtorRiskInfo => {
                this.saldoSumme += parseFloat(debtorRiskInfo['saldo']);
                this.nrsigekauftSumme += parseFloat(debtorRiskInfo['nrsigekauft']);
                this.nrsiumsljSumme += parseFloat(debtorRiskInfo['nrsiumslj']);
                this.limitktoSumme += parseFloat(debtorRiskInfo['ndktlimit']);
              });              
            });
            

            this.riskService.getDebtorRiskInformation(this.debtorNrsiga, this.debtorNmndid, this.debtorNdktid, this.debtorNdktkdnr).subscribe(data => {
              this.debtorRiskInformationDataRows = data;

              for (var i = 0; i < this.debtorRiskInformationDataRows.length; i++) {
                this.limitBez = this.debtorRiskInformationDataRows[i].climkzbeZ1;

                if (this.debtorRiskInformationDataRows[i].ddktaufdat === "0001-01-01T00:00:00") {
                  this.ddktaufdatPruefen = null;
                } else {
                  this.ddktaufdatPruefen = this.debtorRiskInformationDataRows[i].ddktaufdat;
                }

                if (this.debtorRiskInformationDataRows[i].drsibewegdat === "0001-01-01T00:00:00") {
                  this.drsibewegdatPruefen = null;
                } else {
                  this.drsibewegdatPruefen = this.debtorRiskInformationDataRows[i].drsibewegdat;
                }

                if (this.debtorRiskInformationDataRows[i].ddktlimitab === "0001-01-01T00:00:00") {   
                  this.ddktlimitabPruefen = null;
                } else {
                  this.ddktlimitabPruefen = this.debtorRiskInformationDataRows[i].ddktlimitab;
                }

                if (this.debtorRiskInformationDataRows[i].ddktlimitbis === "0001-01-01T00:00:00") {
                  this.dktlimitbisPruefen = null;
                } else {
                  this.dktlimitbisPruefen = this.debtorRiskInformationDataRows[i].ddktlimitbis;
                }

                if (this.debtorRiskInformationDataRows[i].ddktdatumwun === "0001-01-01T00:00:00") {
                  this.datumDddktdatumwunPruefen = null;
                } else {
                  this.datumDddktdatumwunPruefen = this.debtorRiskInformationDataRows[i].ddktdatumwun;
                }

                if (this.debtorRiskInformationDataRows[i].drsilemahng === "0001-01-01T00:00:00") {
                  this.drsimahn = null;
                } else {
                  this.drsimahn = this.debtorRiskInformationDataRows[i].drsilemahng;
                }

                if (this.debtorRiskInformationDataRows[i].ldktwunerl) {
                  this.checked = true;
                  this.disabled = true;
                } else {
                  this.checked = false;
                  this.disabled = true;
                }
              }
              this.selectedRiskLim = this.limitBez;
            });
          }

          if (this.debtorNrsiga > 0) {
            this.riskService.getDebtorRiskBankDetails(this.debtorNrsiga, this.debtorNmndid, this.debtorNdktid, this.debtorNdktkdnr).subscribe(data => {
              this.debtorRiskBankDetailsDataRows = data;

              for (var i = 0; i < this.debtorRiskBankDetailsDataRows.length; i++) {
                this.dktkzlasT1 = this.debtorRiskBankDetailsDataRows[i].ldktkzlasT1;
                this.dktkzlasT2 = this.debtorRiskBankDetailsDataRows[i].ldktkzlasT2;

                if (this.debtorRiskBankDetailsDataRows[i].ldktkzlasT1) {
                  this.checkedlastschrift1 = true;
                  this.disabledlastschrift1 = true;
                } else {
                  this.checkedlastschrift1 = false;
                  this.disabledlastschrift1 = true;
                }

                if (this.debtorRiskBankDetailsDataRows[i].ldktkzlasT2) {
                  this.checkedlastschrift2 = true;
                  this.disabledlastschrift2 = true;
                } else {
                  this.checkedlastschrift2 = false;
                  this.disabledlastschrift2 = true;
                }
              }
            });
          }

          if (this.debtorNrsiga > 0) {
            this.riskService.getDebtorRiskCondition(this.debtorNrsiga, this.debtorNmndid, this.debtorNdktid, this.debtorNdktkdnr).subscribe(data => {
              this.debtorRiskConditionDataRows = data;
            });
          }
        } else {
          this.alertService.showMessage("Die Sicht auf dieses Modul ist nicht freigeschaltet.", "", MessageSeverity.info);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  userrisk: boolean = true;

  /**
  * 
  * @param user
  * @param roles
  */

  

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    //console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewRiskInfo() {
    return this.accountService.userHasPermission(Permission.viewRiskInfoPermission);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  //PDF print
  print() {
    window.print();
  }
}
