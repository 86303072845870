
import { NgModule, ErrorHandler } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToastaModule } from 'ngx-toasta';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PopoverModule } from "ngx-bootstrap/popover";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ChartsModule } from 'ng2-charts';


import { AppRoutingModule } from './app-routing.module';
import { AppErrorHandler } from './app-error.handler';

import { CustomPaginator } from './app-custom-paginator';

import { AppTitleService } from './services/app-title.service';
import { AppTranslationService, TranslateLanguageLoader } from './services/app-translation.service';
import { ConfigurationService } from './services/configuration.service';
import { AlertService } from './services/alert.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { EndpointFactory } from './services/endpoint-factory.service';
import { NotificationService } from './services/notification.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { AccountService } from './services/account.service';
import { AccountEndpoint } from './services/account-endpoint.service';

import { EqualValidator } from './directives/equal-validator.directive';
import { LastElementDirective } from './directives/last-element.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { BootstrapTabDirective } from './directives/bootstrap-tab.directive';
import { BootstrapToggleDirective } from './directives/bootstrap-toggle.directive';
import { BootstrapSelectDirective } from './directives/bootstrap-select.directive';
import { BootstrapDatepickerDirective } from './directives/bootstrap-datepicker.directive';
import { GroupByPipe } from './pipes/group-by.pipe';
import { FilterPipe} from './pipes/searchfilter.pipe';
import { AppComponent } from "./components/app.component";
import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { SettingsComponent } from "./components/settings/settings.component";
/*import { AboutComponent } from "./components/about/about.component";*/
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { ObligoComponent } from "./components/obligo/obligo.component";
import { AobComponent } from "./components/aob/aob.component";
import { BannerDemoComponent } from "./components/controls/banner-demo.component";
import { TodoDemoComponent } from "./components/controls/todo-demo.component";
import { StatisticsDemoComponent } from "./components/controls/statistics-demo.component";
import { NotificationsViewerComponent } from "./components/controls/notifications-viewer.component";
import { SearchBoxComponent } from "./components/controls/search-box.component";
import { UserInfoComponent } from "./components/controls/user-info.component";
import { UserPreferencesComponent } from "./components/controls/user-preferences.component";
import { UsersManagementComponent } from "./components/controls/users-management.component";
import { RolesManagementComponent } from "./components/controls/roles-management.component";
import { RoleEditorComponent } from "./components/controls/role-editor.component";
import { DocumentsComponent } from "./components/documents/documents.component";
import { LimitComponent } from "./components/limit/limit.component";
import { ContactComponent } from "./components/contact/contact.component";
import { OpenLimitRequestsComponent } from "./components/open-limit-requests/open-limit-requests.component";
import { LastLimitDecisionsComponent } from "./components/last-limit-decisions/last-limit-decisions.component";
import { DailyAccountingsComponent } from "./components/daily-accountings/daily-accountings.component";
import { DebtorAccountsComponent } from "./components/debtor-accounts/debtor-accounts.component";
import { InvoiceListComponent } from "./components/invoice-list/invoice-list.component";
import { MonthlyAccountingsComponent } from "./components/monthly-accountings/monthly-accountings.component";
import { RecommendationComponent } from "./components/recommendation/recommendation.component";
import { RiskComponent } from "./components/risk/risk.component";
import { OpenItemsComponent } from "./components/open-items/open-items.component";
import { RemindersComponent } from "./components/reminders/reminders.component";
import { SubmissionsComponent } from "./components/submissions/submissions.component";
import { SubmissionsAllComponent } from "./components/submissions-all/submissions-all.component";
import { SubmissionPopupComponent } from './components/submission-popup/submission-popup.component';
import { AccountCreationComponent } from "./components/account-creation/account-creation.component";
import { AccountChangeComponent } from "./components/account-change/account-change.component";
import { LimitChangeComponent } from "./components/limit-change/limit-change.component";
import { ImprintComponent } from "./components/imprint/imprint.component";

// Test
import { Ng2SearchPipeModule } from 'ng2-search-filter'; //importing the module
import { Ng2OrderModule } from 'ng2-order-pipe'; //importing the module
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module

// Carousel
import { Ng2CarouselamosModule } from 'ng2-carouselamos';


// Material
import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,

  MAT_DATE_LOCALE,
  MatPaginatorIntl
} from '@angular/material';

// Spinner
import { NgxSpinnerModule } from 'ngx-spinner';

// REST Clients
import { ObligoService } from "./services/obligo.service";
import { OpenItemsService } from "./services/open-items.service";
import { RiskService } from "./services/risk.service";
import { HttpModule } from '@angular/http';
import { InvoiceListService } from "./services/invoice-list.service";
import { RecommendationService } from "./services/recommendation.service";
import { ContactService } from "./services/contact.service";


import { JwPaginationComponent } from 'jw-angular-pagination';
import { DownloadService } from "./services/download.service";

import { SmartSignUpService } from "./services/smart-sing-up.service";

//Duallistbox
import { AngularDualListBoxModule } from 'angular-dual-listbox';

//
import { MatAutocompleteModule } from '@angular/material/autocomplete';

//
import { FileDropDirective, FileSelectDirective } from "ng2-file-upload";
import { CustomMaterialModule } from "./material.module";
import { ComboBoxService } from "./services/combobox-service";
import { LimitService } from "./services/limit.service";
import { DebtorAccountService } from "./services/debtor-account.service";
import { CSVService } from "./services/csv.service";
import { NrsigaService } from "./services/nrsiga.service";
import { NaviComponent } from "./components/navi/navi.component";
import { ECrefoService } from "./services/ecrefo-service";

import { DeviceDetectorModule } from 'ngx-device-detector';
import { NaviInfoComponent } from './components/navi-info/navi-info.component';
import { DateiService } from "./services/datei.service";
import { KontoCreationSuccessComponent } from "./components/konto-creation-success/konto-creation-success.component";
import { KontoChangeSuccessComponent } from './components/konto-change-success/konto-change-success.component';
import { LimitChangeSuccessComponent } from './components/limit-change-success/limit-change-success.component';
import { LoginPassChangeComponent } from './components/login-pass-change/login-pass-change.component';
import { DatenschutzComponent } from './components/datenschutz/datenschutz.component';
import { AdminCheckComponent } from './components/admin-check/admin-check.component';
import { CommonModule } from "@angular/common";
import { PopupService } from "./services/popup.service";
import { InfoComponent } from './components/info/info.component';
import { UploadResultsPopupComponent } from './components/upload-results-popup/upload-results-popup.component';







@NgModule({
  imports: [


    CommonModule,
    AppRoutingModule,
    AngularDualListBoxModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    Ng2SearchPipeModule, //including into imports
    Ng2OrderModule, // importing the sorting package here
    NgxPaginationModule,
    Ng2CarouselamosModule,
    DeviceDetectorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    NgxDatatableModule,
    ToastaModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    ChartsModule,
        MatAutocompleteModule,
        CustomMaterialModule
  ],
  exports: [
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    BrowserAnimationsModule,
   
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SettingsComponent,
    UsersManagementComponent, UserInfoComponent,
    UserPreferencesComponent,
    RolesManagementComponent, RoleEditorComponent,

    NotFoundComponent,
    NotificationsViewerComponent,
    SearchBoxComponent,
    StatisticsDemoComponent, TodoDemoComponent, BannerDemoComponent,
    EqualValidator,
    LastElementDirective,
    AutofocusDirective,
    BootstrapTabDirective,
    BootstrapToggleDirective,
    BootstrapSelectDirective,
    BootstrapDatepickerDirective,
    GroupByPipe,
    FilterPipe,
  

    AobComponent,
    ObligoComponent,
    DocumentsComponent,
    LimitComponent,
    ContactComponent,
    OpenLimitRequestsComponent,
    LastLimitDecisionsComponent,
    DailyAccountingsComponent,
    DebtorAccountsComponent,
    InvoiceListComponent,
    MonthlyAccountingsComponent,
    InvoiceListComponent,
    RecommendationComponent,
    RiskComponent,
    OpenItemsComponent,
    RemindersComponent,

    SubmissionsComponent,
    SubmissionsAllComponent,

    AccountCreationComponent,
    AccountChangeComponent,
    LimitChangeComponent,
    ImprintComponent,
    JwPaginationComponent,
    FileDropDirective,
    FileSelectDirective,
    NaviComponent,
    NaviInfoComponent,
    KontoCreationSuccessComponent,
    KontoChangeSuccessComponent,
    LimitChangeSuccessComponent,
    LoginPassChangeComponent,
    DatenschutzComponent,
    AdminCheckComponent,
    SubmissionPopupComponent,
    InfoComponent,
    UploadResultsPopupComponent, 

  
    //ObligoIdComponent
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
      { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' }, //Datepicker dateformat
    { provide: MatPaginatorIntl, useClass: CustomPaginator },

    AlertService,
    ConfigurationService,
    AppTitleService,
    AppTranslationService,
    NotificationService,
    NotificationEndpoint,
    AccountService,
    AccountEndpoint,
    LocalStoreManager,
    EndpointFactory,
    DebtorAccountService,
    ObligoService,
    OpenItemsService,
    RiskService,
    InvoiceListService,
    RecommendationService,
    PopupService,
    ContactService,
    LimitService,
    NrsigaService,
    MatDatepickerModule,
    DownloadService,
    SmartSignUpService,
    ComboBoxService,
    CSVService,
    ECrefoService,
    DateiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}


export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
